<template>
  <el-container>
    <el-header height="220">
      <el-card class="box-card" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <div v-if="albumInfo !== null" slot="header" class="clearfix">
          <el-image
            lazy
            fit="cover"
            class="coverImg"
            :src="albumInfo.coverUrl"
          />
          <el-row>
            <span>当前播放列表</span>
            <el-select
              v-model="queryInfo.albumId"
              clearable
              placeholder="播放列表"
              style="margin-left: 5px"
              @change="onSelectChange"
            >
              <el-option
                v-for="(item, index) in allAlbums"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <span style="padding-left: 20px">
              <span style="color: blue">{{ albumInfo.total }}</span> 个稿件
              <span style="color: red">{{ albumInfo.scopeStr }}</span>
            </span>
            <el-button style="float: right; padding: 10px" type="text" @click="onPlayAlbum">播放全部</el-button>
          </el-row>
        </div>
        <div class="text item" />
      </el-card>
    </el-header>
    <el-main>
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-col :md="24">
          <div v-if="dataList.length === 0" align="center">
            <img src="@/assets/img/icon/not-result.png">
            <div>还没有收藏呢~</div>
          </div>
          <div v-if="dataList.length !== 0">
            <el-col
              v-for="(item, index) in dataList"
              :key="index"
              :md="6"
              :sm="12"
              :xs="12"
              style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px"
            >
              <el-card :body-style="{ padding: '0px' }" class="card">
                <div class="imgs" style="cursor: pointer" :title="item.title">
                  <router-link target="_blank" :to="`/video/${item.videoId}`">
                    <el-image
                      lazy
                      fit="cover"
                      class="coverImg"
                      :src="item.coverUrl"
                    />
                    <span style="position: absolute; top: 0; left: 60%; color:white"> {{ item.duration }} </span>
                    <span style="position: absolute; bottom: 0; left: 0; color:white">
                      <i v-if="item.horizontal" class="el-icon-monitor" />
                      <i v-else class="el-icon-mobile-phone" />
                    </span>
                  </router-link>
                </div>
                <div style="padding: 14px">
                  <router-link style="text-decoration-line: none" target="_blank" :to="`/video/${item.videoId}`">
                    <span style="left: 0;margin-bottom: 0px;color: black;">{{ item.title | ellipsis }}</span>
                  </router-link>
                </div>
                <div style="padding: 14px">
                  <span style="left: 0;margin-bottom: 0px;color: black;">
                    <router-link target="_blank" :to="`/user/${item.user.userId}`">
                      <i class="el-icon-user"> {{ item.user.screenName | ellipsisUsername }} </i></router-link> • {{ item.pubDateStr }}
                  </span>
                </div>
                <div style="padding: 14px;">
                  <el-tooltip class="item" effect="dark" content="删除收藏" placement="top-end">
                    <el-button
                      size="mini"
                      type="danger"
                      class="el-icon-delete"
                      @click="onDeleteItem(item)"
                    />
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="设为封面" placement="top-end">
                    <el-button
                      size="mini"
                      type="warning"
                      class="el-icon-picture-outline"
                      @click="onSetCover(item)"
                    />
                  </el-tooltip>
                </div>
              </el-card>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <el-pagination
        hide-on-single-page
        :small="screenWidth <= 768"
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-main>

    <!-- 创建相册对话框 -->
    <el-dialog
      append-to-body
      :visible.sync="createAlbumDiaglog"
      width="30%"
      center
    />
  </el-container>
</template>

<script>
import {
  collectItem,
  getAlbumItems,
  getUserAlbumSelectList
} from '@/api/collect'

export default {
  name: 'PostAlbum',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 15
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    },
    ellipsisUsername(value) {
      if (!value) return ''
      const max = 10
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  data() {
    return {
      allAlbums: [
        { label: '全部', value: 1 }
      ],
      queryInfo: {
        albumId: null,
        pn: 1
      },
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      albumInfo: null,
      // **********************************************************************
      createAlbumDiaglog: false
    }
  },
  created() {
    this.getSelectList()
    const albumId = this.$route.query.albumId
    if (albumId !== undefined) {
      this.queryInfo.albumId = albumId
    }

    const pn = this.$route.query.pn
    if (albumId !== undefined) {
      this.queryInfo.pn = pn
      this.currentPage = pn
    }

    this.getData()
    document.title = '我的播放列表'
  },
  methods: {
    onSelectChange() {
      this.queryInfo.pn = 1
      this.currentPage = 1
      this.getData()
      this.$router.push({
        path: '/post/album',
        query: this.queryInfo
      })
    },
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.queryInfo.pn = pageNumber
      // this.getData()
      this.$router.push({
        path: '/post/album',
        query: this.queryInfo
      })
      // 回到顶部
      scrollTo(0, 0)
      this.getData()
    },
    getData() {
      getAlbumItems(this.queryInfo).then(resp => {
        if (resp.code === 0) {
          const respData = resp.data
          this.dataList = respData.pageList.list
          this.totalSize = respData.pageList.totalSize
          this.albumInfo = respData.albumInfo
          this.queryInfo.albumId = this.albumInfo.albumId
        }
      }).catch(error => {
        this.$message({
          message: error.message,
          type: 'error',
          duration: 1000
        })
      })
    },
    getSelectList() {
      getUserAlbumSelectList().then(resp => {
        if (resp.code === 0) {
          this.allAlbums = resp.data
        }
      })
    },
    // 设为封面
    onSetCover(item) {
      const jsonData = {}
      jsonData.albumId = this.albumInfo.albumId
      jsonData.postId = item.videoId
      jsonData.action = 3
      collectItem(jsonData).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '封面已更新!'
          })
        }
      })
    },
    // 移除收藏
    onDeleteItem(item) {
      this.$confirm('确认删除本收藏？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const jsonData = {}
        jsonData.albumId = this.albumInfo.albumId
        jsonData.postId = item.videoId
        jsonData.action = 2
        collectItem(jsonData).then(res => {
          if (res.code === 0) {
            this.$router.go(0)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    onPlayAlbum() {
      this.$message.info('暂未实现')
    }
  }
}
</script>

<style>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .tit {
    font-weight: 600;
    font-size: 12px;
    height: 32px;
  }
  .time {
    font-size: 10px;
    color: #999;
  }
  .num {
    font-size: 9px;
    padding-top: 3px;
  }
  .bottom {
    margin-top: 2px;
    line-height: 7px;
  }
  .coverImg {
    height: 120px !important;
  }
}

.coverImg {
  width: 100%;
  height: 90px;
  display: block;
}
</style>
